import React, {useEffect, useState} from 'react';
import './App.css';

import headerMain from './img/sh_wedding_111529_back.png';
import headerWords from './img/sh_wedding_111529.svg';
import salo_de_cent from './img/salo_de_cent.png';
import l_olive from './img/l_olive.png';
import olivo from './img/olivo.jpg';
import wedding_summer from './img/wedding_summer.png';
import olive_fruit from './img/olive_fruit.png';
import ceremonia from './img/ceremonia.svg';
import comida from './img/comida.svg';
import confirmacion from './img/confirmacion.svg';

declare module "react" {
    interface ImgHTMLAttributes<T> extends HTMLAttributes<T> {
        fetchPriority?: 'high' | 'low' | 'auto';
    }
}

enum GuestInvitationType {
    'SINGLE' = 'SINGLE',
    'PLURAL' = 'PLURAL',
}

interface GuestInvitation {
    title: string,
    id: string,
    type: GuestInvitationType,
    vip?: boolean
}

const guestInvitations: GuestInvitation [] = [
    {"title": "Sonia O.", "id": "116efe5a", "type": GuestInvitationType.SINGLE, vip: true},
    {"title": "Harrinson M.", "id": "17859c2c", "type": GuestInvitationType.SINGLE, vip: true},
    {"title": "Bibiana B.", "id": "f3633f9c", "type": GuestInvitationType.SINGLE, vip: true},
    {"title": "Eva M.", "id": "dcf8cf42", "type": GuestInvitationType.SINGLE, vip: true},
    {"title": "Marc O.", "id": "e3c529d8", "type": GuestInvitationType.SINGLE, vip: true},
    {"title": "Sergio M.", "id": "e0c23bb8", "type": GuestInvitationType.SINGLE, vip: true},
    {"title": "Purificación Z.", "id": "f5e1d62a", "type": GuestInvitationType.SINGLE, vip: true},
    {"title": "Harold A. y Natalia T.", "id": "f9d17010", "type": GuestInvitationType.PLURAL, vip: true},
    //{"title": "Aimar F.", "id": "feeedbaa", "type": GuestInvitationType.SINGLE},
    //{"title": "Aridna D. y Jose Á.", "id": "02f4da10", "type": GuestInvitationType.PLURAL},
    //{"title": "Daniel G.", "id": "07efebcc", "type": GuestInvitationType.SINGLE},
    //{"title": "Paulo J.", "id": "0a82d9bc", "type": GuestInvitationType.SINGLE},
    {"title": "Katerine B. y Gonzalo G.", "id": "0d93f33e", "type": GuestInvitationType.PLURAL},
    {"title": "Alba G.", "id": "1289eea2", "type": GuestInvitationType.SINGLE},
    {"title": "Andrea M.", "id": "15afbe9a", "type": GuestInvitationType.SINGLE, vip: true},
    {"title": "Mari Carmen M. y Manel P.", "id": "1850b122", "type": GuestInvitationType.PLURAL},
    {"title": "Marta P.", "id": "1db3ad5e", "type": GuestInvitationType.SINGLE},
    {"title": "Maria Jesus B. y acompañante", "id": "20f78404", "type": GuestInvitationType.PLURAL},
    //{"title": "Samir T.", "id": "fca81789", "type": GuestInvitationType.SINGLE},
    //{"title": "David B.", "id": "b3a9ef64", "type": GuestInvitationType.SINGLE},
    {"title": "Leonard C. y Luisa L.", "id": "32d282a2", "type": GuestInvitationType.PLURAL}
]

function App() {
    const [isScrolled, setIsScrolled] = useState(false);

    const [guestInfo, setGuestInfo] = useState<GuestInvitation>();

    useEffect(() => {
        const section = document.querySelector('section');

        const searchParams = window.location.search;
        const urlSearchParams = new URLSearchParams(searchParams);
        const guestId = urlSearchParams.get('guestId');
        if (guestId) {
            const guestQueryResult = guestInvitations.find(guestInfo => {
                return guestInfo.id === guestId;
            });
            if (guestQueryResult) {
                const invitationElement = document.getElementById('invitation');
                invitationElement && invitationElement.classList.remove('blurred');
                setGuestInfo(guestQueryResult);
            }
        }

        if (section) {
            window.addEventListener('scroll', (event) => {
                if (window.scrollY > 200) {
                    setIsScrolled(true);
                }
            })
        }


    }, [guestInfo]);


    return (
        <div id="invitation" className="container blurred">
            <section>
                <header className="headerImage">
                    <img fetchPriority="high" className="img-responsive headerWords" alt="" title="Harry y Sonia"
                         src={headerWords}/>
                    <img fetchPriority="high" className="img-responsive headerMain" alt="" title="Harry y Sonia"
                         src={headerMain}/>
                </header>
                <div className="date-wrapper">
                    <time dateTime="2024-01-20">
                        <span>SÁB</span> &#124; <strong>20</strong> &#124; <span>ENE</span>
                    </time>
                </div>
                <p className="warning text-wrapper">¡oh vaya, no hemos encontrado tu invitación!</p>
                <p className="text-wrapper guest-greeting">Hola<span>{guestInfo && guestInfo.title}</span></p>
                <p className="text-wrapper">Como
                    ya {guestInfo && guestInfo.type === GuestInvitationType.SINGLE ? "sabes" : "sabéis"}, nos casamos y
                    ¡nos encantaría
                    celebrarlo {guestInfo && guestInfo.type === GuestInvitationType.SINGLE ? "contigo" : "con vosotros"}!</p>
                <p className="text-wrapper">
                    A
                    continuación {guestInfo && guestInfo.type === GuestInvitationType.SINGLE ? "podrás" : "podréis"} ver
                    la información relevante sobre el evento.
                </p>
            </section>
            <img loading="lazy" alt="" className="img-responsive hr" src={olive_fruit} title="Olive"/>
            <section className="row">
                <div className="subtitle">
                    <img loading="lazy" alt="" className="img-responsive" src={ceremonia} title="Ceremonia"/>
                </div>
                <div className="place-wrapper">
                    Saló de Cent
                    <div className="font-small">(Ayuntamiento de Barcelona)</div>
                </div>
                <div className="address">
                    Plaça de Sant Jaume, 1 &middot; Barcelona
                </div>
                <div className="time">11:00</div>
                <div className="map">
                    <a href="https://goo.gl/maps/Uys13AQs35fHnEyT6" title="Saló de Cent">
                        <img loading="lazy" className="img-responsive img-info" src={salo_de_cent} alt=""/>
                    </a>
                </div>
                <img loading="lazy" alt="" className="img-responsive hr" src={olive_fruit} title="Olive"/>
                <div>
                    <img loading="lazy" alt="" className="img-responsive" src={wedding_summer} title="Sonia y Harry"/>
                </div>
            </section>
            <img loading="lazy" alt="" className="img-responsive hr" src={olive_fruit} title="Olive"/>
            <section className="row">
                <div className="subtitle">
                    <img loading="lazy" alt="" className="img-responsive" src={comida} title="Comida"/>
                </div>
                <div className="place-wrapper">
                    Restaurante L'Olivé
                </div>
                <div className="address">
                    Carrer Balmes, 47 &middot; Barcelona
                </div>
                <div className="time">13:00</div>
                { guestInfo && !guestInfo.vip && (<div className="price">Precio por persona - 60€</div>)}
                <div className="map">
                    <a href="https://goo.gl/maps/sjmcnMEzdjAi2WL17" title="Restaurante L'Olivé">
                        <img loading="lazy" className="img-responsive img-info" src={l_olive} alt=""/>
                    </a>
                </div>
            </section>
            <img loading="lazy" alt="" className="img-responsive hr" src={olive_fruit} title="Olive"/>
            { guestInfo && !guestInfo.vip && (
                <section className="row">
                    <div className="subtitle">
                        <img loading="lazy" alt="" className="img-responsive" src={confirmacion} title="Confirmación"/>
                    </div>
                    <p className="text-wrapper">
                        Es importante
                        que {guestInfo && guestInfo.type === GuestInvitationType.SINGLE ? "confirmes tu" : "confirméis vuestra"} asistencia,
                        para ello, {guestInfo && guestInfo.type === GuestInvitationType.SINGLE ? "te" : "os"} dejamos el
                        número de cuenta y nuestro bizum.
                    </p>
                    <dl>
                        <dt>Fecha límite de confirmación</dt>
                        <dd>11 de Diciembre de 2023</dd>
                        <dt>Bizum</dt>
                        <dd className="number">628 76 05 89</dd>
                        <dd className="number">695 72 77 69</dd>
                        <dt>Número de cuenta</dt>
                        <dd className="number">ES09 1465 0180 78 1744678372</dd>
                    </dl>
                </section>
            )}
            <img loading="lazy" alt="" className="img-responsive" src={olivo} title="Olivo"/>
            <div className={"down-arrow " + (isScrolled ? 'hidden' : '')}></div>
            <div className={"down-arrow down-arrow--left " + (isScrolled ? 'hidden' : '')}></div>
        </div>
    );
}

export default App;
